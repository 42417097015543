import { useState } from 'react'
import emailjs from 'emailjs-com'



const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [slideLine,setslideLine] = useState(true);
  const [slideTelegram,setslideTelegram] = useState(true);
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    var frm_name = document.getElementById('name')
    var frm_email = document.getElementById('email')
    var frm_message = document.getElementById('message')
   
    
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_v298rqr', 'template_qe9lfhs', e.target, 'user_AxUyo6HZZ5AkZc9aimwUf'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          
          
        },
        (error) => {
          console.log(error.text)
        }
      )
      frm_name.value='';
      frm_email.value='';
      frm_message.value='';
  }
  function showLine(){
if(slideLine){
  setslideLine(false)
  // document.getElementById('slide_line1').style.height = '50px';
  document.getElementById('slide_line2').style.height = '100px';
  document.getElementById('showText1').style.opacity = '1'
  document.getElementById('showText2').style.opacity = '1'
  setTimeout(() => {
    document.getElementById('showText1').style.visibility = 'visible'
    document.getElementById('showText2').style.visibility = 'visible'
  }, 200);
  
  
}else{
  setslideLine(true)
  // document.getElementById('slide_line1').style.height = '0px';
  document.getElementById('slide_line2').style.height = '0px';
  
    document.getElementById('showText1').style.opacity = '0'
    document.getElementById('showText2').style.opacity = '0'


  setTimeout(() => {
    document.getElementById('showText1').style.visibility = 'hidden'
    document.getElementById('showText2').style.visibility = 'hidden'
  }, 80);
}
    
  }

  function showTelegram(){
    if(slideTelegram){
      setslideTelegram(false)
      // document.getElementById('slide_line1').style.height = '50px';
      document.getElementById('slide_Telegram2').style.height = '100px';
      document.getElementById('showText3').style.opacity = '1'
      document.getElementById('showText4').style.opacity = '1'
      setTimeout(() => {
        document.getElementById('showText3').style.visibility = 'visible'
        document.getElementById('showText4').style.visibility = 'visible'
      }, 200);
      
      
    }else{
      setslideTelegram(true)
      // document.getElementById('slide_line1').style.height = '0px';
      document.getElementById('slide_Telegram2').style.height = '0px';
      
        document.getElementById('showText3').style.opacity = '0'
        document.getElementById('showText4').style.opacity = '0'
    
    
      setTimeout(() => {
        document.getElementById('showText3').style.visibility = 'hidden'
        document.getElementById('showText4').style.visibility = 'hidden'
      }, 80);
    }
      }


  return (  
      <div id='contact' style={{width:'100vw'}} >
   {/* <img src="/public/img/Icon-ClickLine-01.png"  /> */}

       

        <div>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>{props.data ? props.data.get : 'Loading'}</h2>
                <p>{props.data ? props.data.text : 'Loading'}</p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  {props.data ? props.data.send : 'Loading'}
                </button>
              </form>
            </div>
          </div>
      
          <div className='col-md-3 col-md-offset-1 contact-info' >
            <div className='contact-item'>
              <h3>{props.data ? props.data.info : 'Loading'}</h3>
              <p>
                <span>
                  {/* <i className='fa fa-map-marker'></i>{props.data ? props.data.add : 'loading'} */}
                </span>
                {/* {props.data ? props.data.address : 'loading'}<br/>
                {props.data ? props.data.address1 : 'loading'} */}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  {/* <i className='fa fa-phone'></i> {props.data ? props.data.tel : 'loading'} */}
                </span>{' '}
                {/* {props.data ? props.data.phone : 'loading'} */}
              </p>
            </div>
          
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> {props.data ? props.data.mail : 'loading'}
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul style={{marginBottom:'30px'}}>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'} >
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href={props.data ? props.data.linkedin : '/'} >
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li> */}
                  {/* <li >
                    <a href={props.data ? props.data.discord : '/'} >
                    <i  className="fab fa-discord" style={{fontSize: 20 }}></i>

                    </a>
                  </li> */}
                  {/* <li>
                    <a href={props.data ? props.data.reddit : '/'} >
                    <i className="fa fa-reddit" ></i>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href={props.data ? props.data.github : '/'} >
                    <i className="fa fa-github" ></i>
                    </a>
                  </li> */}
                  
                  {/* <li >
                    <a href={props.data ? props.data.blog : '/'} >
                    <i  className="fab fa-blogger" style={{fontSize: 20 }}></i>
                    </a>
                  </li> */}

                  <li onClick={showTelegram} style={{position:'relative'}}>
                   
                    <i  className="fab fa-telegram" style={{fontSize: 20 }}></i>
                    <div className='Telegram2 img-zoom-animation2' id="slide_Telegram2" style={{opacity:'1',whiteSpace:'nowrap'}}>
               
               <div id='showText3' style={{cursor:'pointer',position:'relative',visibility:'hidden',width:'120px',top:'20px',left:'25px',backgroundColor:'#AA0C0F',borderRadius:'50px',fontSize:'13px',opacity:'0',transition:'opacity 0.2s ease 0s'}}> <a href="https://bit.ly/3xx8Dqp" style={{color:'#86FEE2'}}> 
                Telegram Channel
                </a> </div>
               
               <div id='showText4' style={{cursor:'pointer',position:'absolute',visibility:'hidden',width:'130px',top:'52px',left:'20px',backgroundColor:'#AA0C0F',borderRadius:'50px',fontSize:'11px',opacity:'0',transition:'opacity 0.2s ease 0s'}}> <a href="https://t.me/joinchat/TC9TpeNzgTxg3e3j" style={{color:'#86FEE2'}}>
                Telegram Group
                </a> </div>
               
                     </div>  
                    
                  </li>                  
                  <li onClick={showLine} style={{position:'relative'}}>
                                       
                    <i  className="fab fa-line" style={{fontSize: 20 }} ></i>
                    <div className='line2 img-zoom-animation2' id="slide_line2" style={{opacity:'1',whiteSpace:'nowrap'}}>
               
               <div id='showText1' style={{cursor:'pointer',position:'relative',visibility:'hidden',width:'120px',top:'20px',left:'25px',backgroundColor:'#AA0C0F',borderRadius:'50px',fontSize:'13px',opacity:'0',transition:'opacity 0.2s ease 0s'}}> <a href="https://page.line.me/?accountId=070xbawm&openerPlatform=native&openerKey=qrcode&fbclid=IwAR3zt5Gd8D6mAf1s5SOaZIU49XPBRMRo5P82_iB1dG6GjJV9SMDit0Wja38#mst_challenge=NZjBpth-qZUeMoJUAW_UPsTiBnzBYt98Gga5e8fAggI" style={{color:'#86FEE2'}}> 
                VSNT_LineOfficial
                </a> </div>
               
               <div id='showText2' style={{cursor:'pointer',position:'absolute',visibility:'hidden',width:'130px',top:'52px',left:'20px',backgroundColor:'#AA0C0F',borderRadius:'50px',fontSize:'11px',opacity:'0',transition:'opacity 0.2s ease 0s'}}> <a href="http://line.me/ti/g2/kol1mkUtCqzGKLGDgNiO0w?utm_source=invitation&utm_medium=link_copy&utm_campaign=default" style={{color:'#86FEE2'}}>
                Vassana Token Fanclub
                </a> </div>
               
                     </div>                             
                  </li>
                              
                </ul>
              
               
              </div>
            </div>
                         
          </div>
        </div>
      </div>
      </div>
       
      
  )
}
