import { useState, useEffect } from 'react'
import { Faqs } from './components/faqs'
import { Concept } from './components/concept'
import { Service} from './components/service'
import {Tokenomic} from './components/tokenomic'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Roadmap } from './components/roadmap'
//import { Partners } from './components/partners'
// import { Media } from './components/media'
import { Detail } from './components/detail'
import {Listing} from './components/listings'
import { Contact } from './components/contact'
import { PDFReader } from './components/PDFReader'
import { Law } from './components/law'

import JsonData_en from './data/data_en.json'
import JsonData_th from './data/data_th.json'
import SmoothScroll from 'smooth-scroll'


import { BrowserRouter, Link, Route, Switch } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  var data_lang='';
 // const [lang,setlang] = useState("en")
console.log('aaaaaaaaa',sessionStorage.getItem('lang'))

  if(sessionStorage.getItem("lang")==="th" ){
    
     data_lang = JsonData_th 
   
  }
  else{
  
      data_lang = JsonData_en
  }
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    
    setLandingPageData(data_lang)
    
    sessionStorage.setItem('frombutton','false')
    
  }, [])

 
  return (
    <div>
<BrowserRouter>
       

      {/* <Docs data={landingPageData.Docs} /> */}
      <div >
          <Switch>
          <Route exact path = "/" >   
          <Navigation data={landingPageData.Navigation} data2={landingPageData.Navigation2}/>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} data2={landingPageData.Features2} />
      <About data={landingPageData.About} />
      <Concept data={landingPageData.Concept} />
      <Tokenomic data={landingPageData.Tokenomic}/>
      <Service data={landingPageData.Service}/>
      <Roadmap data={landingPageData.Roadmap} data2={landingPageData.Roadmap2} />
      <Faqs data={landingPageData.Faqs} data2={landingPageData.Faqs2}/>
      {/* <Partners data={landingPageData.Partners}/> */}
      <Law data={landingPageData.Law} />
      <Detail data={landingPageData.Detail} data2={landingPageData.Detail2}/>
      {/* <Media data={landingPageData.Media} data2={landingPageData.Media2}/> */}
      <Listing data={landingPageData.Listing} data2={landingPageData.Listing2}/>
   
      <Contact data={landingPageData.Contact} />

          </Route>     
            <Route exact path = "/docs" component={PDFReader}/>
            </Switch>
            </div>
  </BrowserRouter>  
     
    </div>
   

  )
}

export default App;
