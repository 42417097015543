
import React,{useState,useEffect} from 'react'
import Axios from "axios";


export default function Clamtoken() {

  
 
    const [newaddress, setaddress] = useState("");
    const [alert,setalert] = useState("");
// useEffect(() => {
//     document.getElementById("popup-1").classList.toggle("active");
   
// }, [])

const addAddress = () => {
 

if(newaddress.split('').length>=40){

  
  
  Axios.post('https://api.dev.vassanatoken.com/vsnt_api.php', {
    address: newaddress

  }).then((response) => {
    console.log(response.data.msg)
    if (response.data.msg == 'add ok') {
      togglePopup()
      
      setalert('Your wallet address send success')

      toggle_slide3(true,'alert_true')
      setTimeout(() => {
        toggle_slide3(false,'alert_true')
       
      }, 4000);
    
    } else {     
      setalert('You already send this wallet address')  
      toggle_slide3(true,'alert_false')
      togglePopup()     

      setTimeout(() => {  
        toggle_slide3(false,'alert_false')
       
      }, 4000);
      //alert("You already send this wallet address")   
    }
  })
}
else{
  //alert("invalid wallet address")
  setalert('Invalid wallet address')
  toggle_slide3(true,'alert_warn')
  
  setTimeout(() => {  
    toggle_slide3(false,'alert_warn')
   
  }, 4000);
}
}
    function togglePopup() {
      console.log("toggle")
      document.getElementById("popup-1").classList.toggle("active");
    }
    function toggle_slide3(check,alert){ 
      if(check){
        document.getElementById(alert).style.width = '300px';  
     
        

      }else{
        document.getElementById(alert).style.width = '0px'; 
       
      }
     
    }
    return (
      <div>
      <div className="expand-right-alert"  id="alert_false" style={{backgroundColor:'#F8D7DA',color:'#721C24','boxSizing':'content-box',position:'fixed',top:'4%',right:'1',zIndex:'9999',display:'flex',alignItems:'center',justifyContent:'center',overflowWrap:'normal'}}> <div style={{whiteSpace:'nowrap'}}>{alert}</div>  </div>
      <div className="expand-right-alert"  id="alert_warn" style={{backgroundColor:'#FFF3CD',color:'#856404','boxSizing':'content-box',position:'fixed',top:'4%',right:'1',zIndex:'9999',display:'flex',alignItems:'center',justifyContent:'center',overflowWrap:'normal'}}> <div style={{whiteSpace:'nowrap'}}>{alert}</div>  </div>

      <div className="expand-right-alert"  id="alert_true" style={{backgroundColor:'#D4EDDA',color:'#155724','boxSizing':'content-box',position:'fixed',top:'4%',right:'1',zIndex:'9999',display:'flex',alignItems:'center',justifyContent:'center',overflowWrap:'normal'}}> <div style={{whiteSpace:'nowrap'}}>{alert}</div>  </div> 
       
       
        <div className="popup" id="popup-1">
        <div className="overlay" />
        <div className="content">
        <i className="fas fa-times-circle" onClick={togglePopup} style={{float:'right',fontSize:'20px'}}></i>
         
          <div className="d-flex flex-column justify-content-center" style={{display:'flex',marginTop:'30px'}}>
        
          <input 
              type="text" 
              name="wallet" 
              id="wallet"
              className="form-control"
              placeholder="Add your wallet ID of VSNT (valid 21/06/2021)" 
              style={{ fontSize:'8px', maxWidth:'400px'}} 
              
              
              onChange={(event) => {
                setaddress(event.target.value)
              }}
              />
              <button type="submit" className="btn btn-primary" id="bnt-address" style={{maxHeight:'35px', marginLeft:'10px'}} onClick={addAddress}>Send</button>
             
          </div>

        </div>
      </div>
      </div>
    )
}










