import React ,{useEffect,useState}from 'react'
import { Fade } from 'react-slideshow-image';

var  img1;
var  img2;
var  img3;
export const Qa = () => {
  if (sessionStorage.getItem("lang") === "th") {
    img1 = './img/faqs/Q-A-TH-01.png'
    img2='./img/faqs/Q-A-TH-02.png'
    img3='./img/faqs/Q-A-TH-03.png'
  } 
  else {
    img1 = './img/faqs/Q-A-ENG-01.png '
    img2='./img/faqs/Q-A-ENG-02.png'
    img3='./img/faqs/Q-A-ENG-03.png'
  }

  
  const [slideIndex, setslideIndex] = useState(1)
   
  useEffect(() => {
      showSlides()
     
  }, [slideIndex])
function showSlides() {

      var slides = document.getElementsByClassName("mySlides");
      if (slideIndex > slides.length) {setslideIndex(1);  console.log(slideIndex);}

   try {
  
      if (slideIndex < 1) {setslideIndex(slides.length);}
      for (var i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
         
      }
      slides[slideIndex-1].style.display = "block";
      var dots = document.getElementsByClassName("slide-dot");
      for (var i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" slide-dot-active", "");
        
      }
      dots[slideIndex-1].className += " slide-dot-active";
  
  } 
    catch (err) {
       
   }
  }

const plusSlides = (n) => {

 
setslideIndex(slideIndex+n);
showSlides();
}

function currentSlide(n) {

setslideIndex(n)
showSlides();
}


  return (
  
<div className="container">
<div className="slideshow-container">
  <div className="mySlides animated">
   
    <img src={img1} className= "img-responsive"/>
    <div className="slide-text">1/3</div>
  </div>
  <div className="mySlides animated">
   
    <img src={img2} className= "img-responsive"/>
    <div className="slide-text">2/3</div>
  </div>
  <div className="mySlides animated">
    
    <img src={img3} className= "img-responsive" />
    <div className="slide-text">3/3</div>
  </div>
  
  <div>
<div className="slide-prev" onClick={() => plusSlides(-1)}>❮</div>
<div className="slide-next" onClick={() => plusSlides(1)}>❯</div>
</div>
<div style={{display:'flex',justifyContent:'center'}}>
<div className ="slide-dot-control" >
<div className ="slide-dot" onClick={() => currentSlide(1) }></div>
<div className ="slide-dot" onClick={() => currentSlide(2)}></div>
<div className ="slide-dot" onClick={() => currentSlide(3)}></div>

</div>

</div>

</div>
</div>

  )
}