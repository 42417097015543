var bg; 
export const Law = (props) => {
   if(sessionStorage.getItem('lang')==='th'){
      bg = 'law_th';
  }else{
     bg ='law';
  }
     return (
       <div id='law' className='text-center'>
             <div className='container'>
             <div className='section-title'>
             <h2>{props.data ? props.data.head : 'loading...'}</h2>
             {/* <p>
               Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
               dapibus leonec.
             </p> */}
           </div>
               <div className='row' style={{marginBottom:"-100px"}}>
                 <div className={bg}>
                   {/* <h1>
                     {props.data ? props.data.title : 'Loading'}
                     <span></span>
                   </h1> */}
                   {/* <p>{props.data ? props.data.paragraph : 'Loading'}</p> */}
                 </div>
               </div>
             </div>
         </div>
       
     )
   }