import React,{useState,useEffect} from "react";
// import { Transition, animated } from "react-spring";

import { Qa } from "../components/qa"

export const Faqs = (props) => {

  
  return (
    <div id="faqs" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.head : "loading..."}</h2>
          
        </div>


        <Qa></Qa>
           
        </div>
        <h3 style={{marginTop:'100px'}}>{props.data ? props.data.video4 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px',marginLeft:'auto', marginRight:'auto',width:'50vw' }}>
        <video  height={480} controls>
              <source src="video/v4.mp4" type="video/mp4" />
            </video>

        </div>
        <h3>{props.data ? props.data.video1 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px',marginLeft:'auto', marginRight:'auto',width:'50vw' }}>
        <video  height={480} controls>
              <source src="video/v1.mp4" type="video/mp4" />
            </video>
        </div>
        <h3 style={{marginTop:'100px'}}>{props.data ? props.data.video2 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px',marginLeft:'auto', marginRight:'auto',width:'50vw' }}>
        <video  height={480} controls>
              <source src="video/v2.mp4" type="video/mp4" />
            </video>
            </div>
            <h3 style={{marginTop:'100px'}}>{props.data ? props.data.video3 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px',marginLeft:'auto', marginRight:'auto',width:'50vw' }}>
        <video  height={480} controls>
              <source src="video/v3.mp4" type="video/mp4" />
            </video>

        </div>
      
      
      
    </div>
  );
};
